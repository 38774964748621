<template>
  <div>
    <amplify-authenticator>
      <amplify-sign-in
        header-text="Risk Score Sign In"
        slot="sign-in"
        submit-button-text="SIGN IN"
        username-alias="email"
        :hide-sign-up="true"
      ></amplify-sign-in>
      <amplify-loading>
        <v-container>
          <div class="h-100 d-flex flex-column align-center justify-center">
            <v-progress-circular
              indeterminate
              size="70"
              color="primary"
            ></v-progress-circular>
            <h3 class="mt-4">Signing in...</h3>
          </div>
        </v-container></amplify-loading
      >
    </amplify-authenticator>
  </div>
</template>

<script>
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

export default {
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    });
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
      formFields: [{ type: "email" }, { type: "password" }],
    };
  },
  beforeDestroy() {
    this.unsubscribeAuth();
  },
};
</script>

<style lang="scss" scoped></style>
