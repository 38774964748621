export function validatePhone(num) {
  const data = {
    phone: num,
  };

  return fetch(process.env.VUE_APP_SCORE_API + "phone_validate", {
    method: "POST",
    headers: {
      // Authorization: `Bearer ${process.env.NEEDTOKEN}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      // console.log("response ===> ", response);
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Something went wrong validating phone number");
      }
    })
    .then((data) => {
      if (data.success) {
        return data;
      } else {
        console.log("data from validation", data);
        throw new Error(data.details[0].message);
      }
    })
    .catch((error) => {
      //   const e = JSON.stringify(error);
      return error;
    });
}
