<template>
  <v-container class="mt-4">
    <div v-if="loading" class="d-flex align-center justify-center mb-8 d-none">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-show="!loading">
      <form id="payment-form" @submit.prevent="submitStripe">
        <div ref="paymentElement" id="payment-element">
          <!-- Elements will create form elements here -->
        </div>
        <v-btn
          type="submit"
          color="primary"
          x-large
          class="mt-8 font-weight-bold"
          id="submit"
          >Submit</v-btn
        >
        <div v-if="message" id="error-message">
          <div v-html="message" class="error--text"></div>
        </div>
      </form>
    </div>
  </v-container>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";

export default {
  data() {
    return {
      loading: false,
      message: null,
      stripe: null,
      stripeElements: null,
    };
  },
  mounted() {
    this.initStripe();
  },
  methods: {
    async initStripe() {
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUB_KEY);
      const options = {
        // clientSecret: "{{CLIENT_SECRET}}",
        // // Fully customizable with appearance API.
        // appearance: {
        //   /*...*/
        // },
        mode: "subscription",
        currency: "usd",
        amount: 1099,
        paymentMethodCreation: "manual",
      };

      this.stripeElements = this.stripe.elements(options);
      // Create and mount the Payment Element
      const paymentElement = this.stripeElements.create("payment");
      paymentElement.mount(this.$refs.paymentElement);
      this.loading = false;
    },
    async submitStripe() {
      console.log("elements", this.stripeElements);
      const elements = this.stripeElements;

      const that = this;

      elements
        .submit()
        .then(function (result) {
          // Handle result.error
          console.log("elements.submit result", result);
          that.loading = true;
          if (!result.error) {
            that.stripe
              .createPaymentMethod({
                elements,
                // params: {
                //   billing_details: {
                //     name: 'Jenny Rosen',
                //   },
                // },
              })
              .then(function (result) {
                console.log("RESULT", result);
                // Handle result.error or result.paymentMethod
                if (result.paymentMethod) {
                  that.$emit("stripePaymentMethod", result.paymentMethod.id);
                } else if (result.error) {
                  throw result.error;
                } else {
                  that.message =
                    "An error occurred submitting your payment. Please refresh and try again.";
                }
                that.loading = false;
              });
          } else {
            throw result.error;
          }
        })
        .catch((error) => {
          console.log("stripe submit error", error);
          this.message = error;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
