<template>
  <v-container class="mt-6">
    <v-row v-if="message" class="align-center mb-8">
      <v-col class="text-center">
        <v-card outlined max-width="800" elevation="12" class="mx-auto">
          <v-card-text>
            <div v-if="!successRegister" class="mt-2 mb-0">
              <p>
                <strong class="mr-1">Current Subscribers:</strong>
                <router-link to="/signin">Sign in</router-link>
              </p>
            </div>

            <div v-html="message"></div>

            <router-link v-if="successRegister" to="/signin"
              >Sign in to your Risk Score account</router-link
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="!message">
      <v-card maxWidth="800" elevation="12" class="mx-auto">
        <v-card-title>Advisor Registration</v-card-title>
        <v-card-subtitle class="mt-0 pb-0">
          Already have an account?
          <router-link to="/signin">Sign in</router-link></v-card-subtitle
        >
        <v-card-subtitle class="pb-0"
          >Please complete the following information to complete your account
          registration.</v-card-subtitle
        >
        <v-card-text>
          <v-stepper v-model="step" flat tile>
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">
                Details
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 2" step="2">
                Enroll
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3"> Sign In </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form
                  ref="signUpFormRef"
                  @submit.prevent="handlePromoProceed()"
                  v-model="valid"
                  class="mt-4"
                >
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="userPromoCode"
                        :rules="promoCodeRules"
                        label="Promo Code"
                        outlined
                        clearable
                        required
                        type="text"
                        class="rs-text-field--required"
                        :error-messages="userPromoCodeErrorMessage"
                        @input="userPromoCodeErrorMessage = null"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="formData.firstName"
                        :rules="firstNameRules"
                        label="First Name"
                        outlined
                        clearable
                        required
                        type="text"
                        class="rs-text-field--required"
                      ></v-text-field
                    ></v-col>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="formData.lastName"
                        :rules="lastNameRules"
                        label="Last Name"
                        outlined
                        clearable
                        required
                        type="text"
                        class="rs-text-field--required"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="formData.email"
                        :rules="emailRules"
                        label="Email Address"
                        outlined
                        clearable
                        required
                        type="email"
                        class="rs-text-field--required"
                      ></v-text-field
                    ></v-col>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="formData.phone"
                        v-maska="'###-###-####'"
                        label="Phone Number"
                        outlined
                        clearable
                        type="tel"
                        class="rs-text-field--required"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="formData.password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="passwordRules"
                        :type="showPassword ? 'text' : 'password'"
                        name="password"
                        label="Password"
                        counter
                        outlined
                        clearable
                        required
                        class="rs-text-field--required"
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="confirmPassword"
                        :append-icon="
                          showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :rules="passwordRules"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        name="confirmPassword"
                        label="Confirm Password"
                        counter
                        outlined
                        clearable
                        class="rs-text-field--required"
                        @click:append="
                          showConfirmPassword = !showConfirmPassword
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-2">
                      <p>
                        By creating an account you are agreeing to the Social
                        Security Risk Score
                        <router-link
                          :to="{ name: 'TermsOfService' }"
                          target="_blank"
                          title="Terms of Service"
                          >terms of service</router-link
                        >.
                      </p>
                      <v-checkbox
                        v-model="formData.acceptRegistrationTerms"
                        label="Accept Terms of Service"
                        :rules="acceptTermsRules"
                        required
                        class="m-0 rs-text-field--required"
                      ></v-checkbox
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p>
                        <strong
                          >Please complete all required fields before
                          continuing.</strong
                        >
                      </p>
                      <v-btn
                        type="submit"
                        color="primary"
                        x-large
                        :disabled="!valid"
                        class="font-weight-bold"
                        >Continue to Enroll</v-btn
                      ></v-col
                    >
                  </v-row>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2">
                <div
                  v-if="stepLoading"
                  class="d-flex align-center justify-center"
                >
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <template v-else>
                  <h2 v-if="organization" class="mb-4">
                    {{ organization.name }}
                  </h2>
                  <v-alert v-if="promo" outlined text prominent type="info">
                    <strong>{{ promo.description }}</strong>
                  </v-alert>

                  <AuthStripeSubscribe @stripePaymentMethod="handleSignUp" />
                </template>
              </v-stepper-content>

              <v-stepper-content step="3">
                <h3 class="mb-2">Welcome, {{ formData.firstName }}!</h3>
                <p>You are now registered!</p>
                <AuthSignIn />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </template>
    <v-snackbar
      v-model="showSnackBar"
      :timeout="5000"
      :color="snackBarColor"
      :elevation="24"
      transition="slide-x-reverse-transition"
      bottom
      tile
    >
      <div class="text-center">{{ snackBarText }}</div>
    </v-snackbar>
  </v-container>
</template>
<script>
import { API } from "aws-amplify";
import goTo from "vuetify/lib/services/goto";
import { validatePhone } from "@/services/validatePhoneNumber";
import AuthStripeSubscribe from "@/components/AuthStripeSubscribe.vue";
import AuthSignIn from "../components/AuthSignIn.vue";

export default {
  components: {
    AuthStripeSubscribe,
    AuthSignIn,
  },
  data() {
    return {
      step: 1,
      valid: false,
      initLoading: false,
      stepLoading: false,
      user: undefined,
      authState: undefined,
      message: null,
      userPromoCodeErrorMessage: null,
      successRegister: null,
      organization: null,
      showPassword: false,
      showConfirmPassword: false,
      userPromoCode: null,
      confirmPassword: null,
      promo: null,
      formData: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        acceptRegistrationTerms: true,
        password: null,
        collectContactPostSurvey: true,
        promo_code: null,
        payment_method: null,
        scoreReportTemplateId: "",
        advisorId: null,
        organization_id: null,
      },
      showSnackBar: false,
      snackBarText: "",
      snackBarColor: "",
      promoCodeRules: [
        (v) => !!v || "Promo code is required.",
        // (v) => (v && this.handleCheckPromoCodeMatch()) || "Promo code is invalid.",
      ],
      firstNameRules: [
        (v) => !!v || "First name is required",
        (v) =>
          (v && v.length >= 2) || "First name must be at least two letters",
      ],
      lastNameRules: [
        (v) => !!v || "Last name is required",
        (v) => (v && v.length >= 2) || "Last name must be at least two letters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      zipCodeRules: [
        (v) =>
          !v ||
          /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) ||
          "Please enter a valid postal code",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
        () => this.passwordConfirmationRule || "Passwords must match",
      ],
      phoneRules: [
        (v) => !!v || "Phone number is required",
        (v) => (v && this.handleValidatePhone(v)) || "Enter valid phone number",
      ],
      acceptTermsRules: [(v) => !!v || "Accept terms is required"],
    };
  },
  computed: {
    passwordConfirmationRule() {
      return this.formData.password === this.confirmPassword;
    },
  },
  watch: {
    confirmPassword: {
      async handler() {
        this.$nextTick(() => {
          // to trigger confirm password fields match
          this.$refs.signUpFormRef.validate();
        });
      },
    },
  },
  methods: {
    async handlePromoProceed() {
      try {
        this.stepLoading = true;

        if (this.userPromoCode) {
          const promo = await this.fetchPromo(this.userPromoCode);
          if (promo) {
            if (promo.organizationId) {
              this.organization = await this.fetchOrganization(
                promo.organizationId
              );
            }
            this.promo = promo;
          } else {
            return;
          }
        }

        this.step = 2;
      } catch (error) {
        console.log("Error fetching promo:", error);
        this.userPromoCode = null;
        this.userPromoCodeErrorMessage =
          "Promo Code is invalid. Please try again.";
        this.snackBarColor = "error";
        this.snackBarText = "Promo Code is invalid. Please try again.";
        this.showSnackBar = true;
        goTo(0);
      } finally {
        this.stepLoading = false;
        goTo(0);
      }
    },
    async fetchPromo(code) {
      const codeLower = code.toLowerCase();
      const getPromoQuery = `
        query GetPromo($promoCode: String!) {
          getPromo(promoCode: $promoCode) {
            active
            description
            organizationId
          }
        }
        `;

      const response = await API.graphql({
        query: getPromoQuery,
        variables: { promoCode: codeLower },
        authMode: "AWS_IAM",
      });
      const promo = response.data.getPromo;

      if (promo) {
        if (!promo.active) {
          throw new Error(`inactive`);
        } else {
          return promo;
        }
      } else {
        console.log("Error fetching promo:", response.errors[0].message);
        throw new Error(
          `Sorry, an error occurred locating promo. Please try again.`
        );
      }
    },
    async fetchOrganization(id) {
      const getOrgQuery = `
        query GetOrganization($id:  ID!) {
          getOrganization(id: $id) {
            active
            name
          }
        }
        `;

      const response = await API.graphql({
        query: getOrgQuery,
        variables: { id: id },
        authMode: "AWS_IAM",
      });

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      const org = response.data.getOrganization;
      if (org) {
        if (!org.active) {
          throw new Error(`<p><strong>Account is inactive.</strong></p>
            <p>Please contact your account owner if you need assistance or contact support at <a href='mailto:support@riskscore.us?subject=Advisor Sign Up Support' title='Contact Support'>support@riskscore.us</a>.</p>`);
        } else {
          return org;
        }
      } else {
        throw new Error(
          "Organization could not be found. Please contact your organization for assistance."
        );
      }
    },
    handleCheckUrl(e) {
      let urlPattern = new RegExp(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim
      );

      let string = e;

      if (!string) {
        return true;
      }

      if (urlPattern.test(string)) {
        string = string.replace("https://", "").replace("http://", "");
        string = `https://${string}`;

        this.formData.companyUrl = string;
        return true;
      } else {
        return "Please enter a valid url.";
      }
    },
    async handleValidatePhone(num) {
      try {
        // validate phone number
        const response = await validatePhone(num);

        if (response.name === "Error") {
          throw new Error(response.message);
        } else {
          return true;
        }
      } catch (errorMsg) {
        this.message = `
              <p><strong>${errorMsg}</strong></p>
              <p class="error--text"><strong>${num}</strong></p>
              <p>Please enter a valid US phone number for the advisor.</p>`;
        this.showOverlay = false;
        return false;
      }
    },
    handleSignUp(stripePaymentMethodId) {
      if (!this.valid && !stripePaymentMethodId) {
        return;
      }

      this.stepLoading = true;
      goTo(0);

      this.formData.promo_code = this.promo.promoCode;
      this.formData.organization_id = this.promo.organizationId;
      this.formData.payment_method = stripePaymentMethodId;

      // if (this.formData.promo?.duration) {
      //   // if duration is set, calculate the endDate from the duration plus current date
      //   const dur = this.formData.promo.duration;
      //   var daysToAdd = Number(dur) + 1; // add one to bill the day after duration ends

      //   if (!isNaN(daysToAdd)) {
      //     let currentDate = new Date();
      //     // Add duration to the current date
      //     currentDate.setDate(currentDate.getDate() + daysToAdd);

      //     // Format the date in the AWSDATETIME format
      //     this.formData.promo.endDate = currentDate.toISOString();
      //   }
      // }

      // console.log("this.formData", this.formData);

      fetch(process.env.VUE_APP_SCORE_API + "advisor_register", {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${process.env.NEEDTOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.formData),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(
              "Something went wrong registering your account",
              response
            );
          }
        })
        .then((data) => {
          if (data.success) {
            this.stepLoading = false;
            this.step = 3;
          } else {
            console.log("Error data:", data);
            throw new Error(data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.message = `<h3>${error}</h3>`;

          this.stepLoading = false;
        });
    },
  },
};
</script>
